import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lottie from 'lottie-web';

import boatsJson from './data';
import { Regions } from './Regions';
import WarBeginPopup from './WarBeginPopup/WarBeginPopup';

import {
    isAttacking,
    setActiveTeam,
    setAttackTeam
} from '../../store/modules/Team/actions';
import { PAGE_GAME_PATH } from '../../router/constants';

import style from './Maps.module.scss';

const teamList = ['doge', 'pepe', 'wojak'];

export default function MapRegions() {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [isBeginVisible, setBeginVisible] = useState(false);

    const warTeam = parseInt(useSelector(state => state.round?.cycleInfo?.warTeam));
    const stageStart = parseInt(useSelector(state => state.round?.cycleInfo?.stageStart));
    const victimTeam = parseInt(useSelector(state => state.round?.cycleInfo?.victimTeam));
    const stageIndex = parseInt(useSelector(state => state.round?.cycleInfo?.stageIndex));
    const {attacking, activeTeam, attackTeam} = useSelector(state => state.team);

    const isWarStageAppear = warTeam < 3 && victimTeam < 3 && stageIndex === 4;
    const hasWarTeam = warTeam < 3 && teamList[warTeam];
    const hasVictimTeam = victimTeam < 3 && teamList[victimTeam];

    // const hasWarTeam = teamList[0];
    // const hasVictimTeam = teamList[1];

    const id = 'boats-container';

    useEffect(() => {
        if (!isWarStageAppear) return;
        if (!stageStart) return;

        if (Math.floor(Date.now() / 1000) - stageStart < 20) {
            setBeginVisible(true);
        } else {
            dispatch(setActiveTeam(hasWarTeam));
            dispatch(setAttackTeam(hasVictimTeam));
            dispatch(isAttacking(true));
        }
    }, [isWarStageAppear, stageStart, isBeginVisible]);

    useEffect(() => {
        lottie.destroy('boat_animation');
        let animation = lottie.loadAnimation({
            container: document.getElementById(id),
            animationData: boatsJson,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            name: 'boat_animation',
        });

        animation.setSpeed(1.3);

        animation.play();

        return () => {
            if (!window.location.pathname.includes(PAGE_GAME_PATH)){
                lottie.destroy('boat_animation');
            }
        }
    }, []);

    useEffect(async () => {
        if (!isWarStageAppear) return lottie.destroy('attack_animation');

        if (activeTeam && attackTeam) {
            let dataJson = null;

            if (activeTeam === 'doge' && attackTeam === 'pepe') {
                dataJson = await import('./attackAnimation/doge_pepe');
            } else if (activeTeam === 'doge' && attackTeam === 'wojak') {
                dataJson = await import('./attackAnimation/doge_wojak');
            } else if (activeTeam === 'pepe' && attackTeam === 'doge') {
                dataJson = await import('./attackAnimation/pepe_doge');
            } else if (activeTeam === 'pepe' && attackTeam === 'wojak') {
                dataJson = await import('./attackAnimation/pepe_wojak');
            } else if (activeTeam === 'wojak' && attackTeam === 'doge') {
                dataJson = await import('./attackAnimation/wojak_doge');
            } else if (activeTeam === 'wojak' && attackTeam === 'pepe') {
                dataJson = await import('./attackAnimation/wojak_pepe');
            }

            if (dataJson) {
                lottie.destroy('attack_animation');
                let attackAnimation = lottie.loadAnimation({
                    container: document.getElementById('attack_animation'),
                    animationData: dataJson,
                    renderer: 'svg',
                    loop: 15,
                    autoplay: true,
                    name: 'attack_animation'
                });

                attackAnimation.play();

                attackAnimation.addEventListener('complete', () => {
                    dispatch(setActiveTeam(null));
                    dispatch(setAttackTeam(null));
                    dispatch(isAttacking(false));
                    attackAnimation.destroy('attack_animation');
                });

                return () => {
                    attackAnimation.removeEventListener('complete')
                }
            }
        }

        return () => {
            lottie.destroy('attack_animation');
        }
    }, [activeTeam, attackTeam, isWarStageAppear]);

    const handleSelect = (region) => {
        setSelected(true);
        setSelectedRegion(region);
        dispatch(setActiveTeam(region));
    };

    const handleBlur = () => {
        setSelected(false);
        setSelectedRegion('');
    };

    return (
        <>
            <div className={style.mapRegions}>
                <Regions
                    onClick={handleSelect}
                    onBlur={handleBlur}
                    selectedRegion={selectedRegion}
                    selected={selected} />
            </div>
            <div id={id} className={style.boats}/>
            <div className={style.attackContainer} style={{display: attacking ? 'block' : 'none'}}>
                <div id='attack_animation' />
            </div>

            {isBeginVisible && (
                <WarBeginPopup
                    onEnd={setBeginVisible}
                    visible={isBeginVisible}
                    activeTeam={hasWarTeam}
                    attackTeam={hasVictimTeam}
                />
            )}

        </>
    )
}