import React from 'react';
import cn from 'classnames';

import Button from '../../../components/Button';

import mWarsCoin from '../../../assets/img/home/mwars-coin.png';
import gameIcon from '../../../assets/img/home/game-icon.png';
import bnbIcon from './assets/bnb.png';
import bgImage from './assets/section1-bg.png';
import stackIcon1 from './assets/i-stack-1.svg';
import stackIcon2 from './assets/i-stack-2.svg';
import stackIcon3 from './assets/i-stack-3.svg';
import stackIcon4 from './assets/i-stack-4.svg';
import style from './SectionOne.module.scss';
import { useHistory } from 'react-router-dom';

export default function SectionOne() {
    const history = useHistory();
    return (
        <section className={style.sectionOne}>
            <div className={style.headerContainer}>
                <section className={style.leftHeader}>
                    <div className={style.title}>
                        <h1 className={style.heading}>Welcome to MemeWars</h1>
                    </div>
                    <p className={style.detail}>
                        The first ever DeFi game that allows players to strategically stake, defend and attack enemy
                        staking pools. Full game launching on Dec 23rd, 2021. <a style={{ color: '#0089fe' }} href="https://medium.com/@MemeWars/introducing-the-memewars-gamepaper-learn-how-to-play-stake-earn-with-memewars-3804c2891c9b" target="_blank" rel="noreferrer">Read GamePaper</a>
                    </p>
                    <section className={style.buttonContainer}>
                        <Button
                            compact
                            light
                            onClick={() => history.push('/game')}
                            wrapperClass={cn(style.comingBtn, style.ido)}
                        >
                            <img
                                src={mWarsCoin}
                                alt="pancake"
                                className={style.pancakeIcon}
                            />
                            <span>Play Game</span>
                        </Button>

                        <Button
                            compact
                            light
                            wrapperClass={cn(style.comingBtn, style.game)}
                            onClick={() => {
                                window.open('https://medium.com/@MemeWars/introducing-the-memewars-gamepaper-learn-how-to-play-stake-earn-with-memewars-3804c2891c9b', '_blank')
                            }}
                        >
                            <img src={gameIcon} alt="pancake" className={style.pancakeIcon} style={{ marginBottom: '3rem' }}/>
                            <span>Read Game Paper</span>
                        </Button>
                    </section>

                    <section className={style.binanceSmart}>
                        <p className={style.text1}>Exclusively on</p>
                        <img src={bnbIcon} alt="bnb" className={style.bnbIcon}/>
                        <p className={style.text2}>Binance Smart Chain</p>
                    </section>
                </section>

                <section className={style.rightHeader}>
                    <img src={bgImage} alt="" className={style.baseImage}/>
                    <img src={stackIcon1} alt="" className={style.iStack1}/>
                    <img src={stackIcon2} alt="" className={style.iStack2}/>
                    <img src={stackIcon3} alt="" className={style.iStack3}/>
                    <img src={stackIcon4} alt="" className={style.iStack4}/>
                </section>
            </div>
        </section>
    )
}
