import Web3 from 'web3';
import { filter, get } from 'lodash';
import { createAction, createActions } from 'redux-actions';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Logger from 'js-logger';
import BigNumber from 'bignumber.js';
import { message } from 'antd';

import User from './api';
import {ALLOWED_NETWORKS} from './constants';
import WarTokenABI from '../../../abi/warTokenAbi';
import PresenterABI from '../../../abi/presenterAbi';
import { contractAddresses } from '../../../utils';
import { getUserInfo } from '../../../utils/callHelpers';
import { setConnectModalVisible } from '../App/actions';

export const setWalletInfo = createAction('SET_WALLET_INFO');
export const setAppliedTermsOfService = createAction('SET_APPLIED_TERMS_OF_SERVICE');
export const setChainId = createAction('SET_NETWORK');
export const setWrongNetwork = createAction('SET_WRONG_NETWORK');
export const setUserAccounts = createAction('SET_USER_ACCOUNTS');
export const setBalance = createAction('SET_BALANCE');
export const setUserInfo = createAction('SET_USER_INFO');

/* CREATE TRANSACTION LOG */
export const storeTransactionLog = createAction('STORE_TRANSACTION_LOG');

/* CLEAR TRANSACTION LOGS */
export const clearTransactionLogs = createAction('CLEAR_TRANSACTION_LOGS');

export const fetchWalletInfo = address => (dispatch) => {
    User.fetchWalletInfo(address)
        .then(result => (
            dispatch(setWalletInfo(result.data))
        ))
        .catch(err => err);
};

const { sendVerifyEmailRequest, sendVerifyEmailSuccess, sendVerifyEmailFail } = createActions({
    SEND_VERIFY_EMAIL_REQUEST: () => { },
    SEND_VERIFY_EMAIL_SUCCESS: data => ({ data }),
    SEND_VERIFY_EMAIL_FAIL: error => ({ error })
});

export const sendVerifyEmail = body => dispatch => {
    dispatch(sendVerifyEmailRequest);
    return User.sendVerifyEmail(body)
        .then(({ data }) => {
            message.success('Verify Email Sent! Please check your email.');
            dispatch(sendVerifyEmailSuccess(data));
        })
        .catch(error => {
            message.error(error?.data?.message);
            sendVerifyEmailFail(error);
        })
}

export const checkVerifyEmail = body => dispatch => {
    return User.checkVerifyEmail(body)
        .then(() => {
            dispatch(fetchWalletInfo(body.address))
            return true;
        })
        .catch(error => error)
}

export const registerNotify = body => dispatch => {
    return User.registerNotify(body)
        .then(() => {
            dispatch(fetchWalletInfo(body.address))
            return true;
        })
        .catch(error => {
            message.error(error?.data?.message);
            return error?.data?.message;
        })
}

const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
    CONNECT_WALLET_REQUEST: () => { },
    CONNECT_WALLET_SUCCESS: data => ({ data }),
    CONNECT_WALLET_FAIL: error => ({ error })
});

export const setNetwork = (chainId) => (dispatch) => {
    dispatch(setChainId(chainId));
    dispatch(validateNetwork(chainId));
};

export const validateNetwork = (chainId) => (dispatch, getState) => {
    const {wrongNetwork} = getState().user;
    if (chainId) {
        const hexChainId = `${chainId.toString().slice(0,2) === '0x' ? '' : '0x'}${chainId.toString(16)}`;
        const isValid = ALLOWED_NETWORKS.indexOf(hexChainId) !== -1;

        if (!isValid) {
            dispatch(setWrongNetwork(true));
        } else if (isValid) {
            dispatch(setWrongNetwork(false));
        }
    } else if (wrongNetwork) {
        dispatch(setWrongNetwork(false));
    }
};

export const connectMetaMask = (isMobile, needLoad = true) => async dispatch => {
    needLoad && dispatch(connectWalletRequest());

    // Check metamask is install or not
    if (window.ethereum) {
        if (window.ethereum.isMetaMask) {
            window.ethereum.autoRefreshOnNetworkChange = false;
        }

        const provider = await detectEthereumProvider();
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.ethereum);
        }

        window.ethereum.on('accountsChanged', async (accounts) => {
            // const chainId = window.ethereum.chainId;
            // const hexChainId = window.web3.utils.toHex(chainId);

            // if (ALLOWED_NETWORKS.indexOf(hexChainId) === -1) {
            //     await clearUserInfo(dispatch);
            //     dispatch(setWrongNetwork(true));
            //     return;
            // }else {
            //     dispatch(setWrongNetwork(false));
            // }
 
            // await clearUserInfo(dispatch);
            
            if (accounts || accounts[0]) {
                const chainId = window.ethereum.chainId;
                const accounts = await window.web3.eth.getAccounts();
                const balance = await window.web3.eth.getBalance(accounts[0]);

                const warTokenContract = new window.web3.eth.Contract(WarTokenABI, contractAddresses(chainId)['WARTOKEN']);
                const tokenBalance = await warTokenContract.methods.balanceOf(accounts[0]).call();
                dispatch(setUserAccounts({ accounts, balance, chainId, tokenBalance: Web3.utils.fromWei(tokenBalance) }));

                const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
                const dogeInfo = await getUserInfo(presenterContract, accounts[0], 0);
                const pepeInfo = await getUserInfo(presenterContract, accounts[0], 1);
                const wojakInfo = await getUserInfo(presenterContract, accounts[0], 2);

                dispatch(setUserInfo({dogeInfo, pepeInfo, wojakInfo}));
                dispatch(fetchWalletInfo(accounts[0]));
            } else {
                dispatch(setUserAccounts({ accounts }));
                // dispatch(disconnect());
            }
        });

        window.ethereum.on('chainChanged', async (chainId) => {
            window.web3 = new Web3(window.ethereum);
            const accounts = await window.web3.eth.getAccounts();
            dispatch(setNetwork(chainId));

            if (ALLOWED_NETWORKS.indexOf(verifyChainId(chainId)) === -1) {
                const balance = await window.web3.eth.getBalance(accounts[0]);
                const warTokenContract = new window.web3.eth.Contract(WarTokenABI, contractAddresses(chainId)['WARTOKEN']);
                const tokenBalance = await warTokenContract.methods.balanceOf(accounts[0]).call();
                // await clearUserInfo(dispatch);
                dispatch(setConnectModalVisible(false));
                dispatch(setWrongNetwork(true));
                dispatch(setUserAccounts({ accounts, balance, chainId, tokenBalance: Web3.utils.fromWei(tokenBalance) }));
                return;
            } else {
                dispatch(setWrongNetwork(false));
            }

            if (accounts && accounts[0]) {
                const balance = await window.web3.eth.getBalance(accounts[0]);

                const warTokenContract = new window.web3.eth.Contract(WarTokenABI, contractAddresses(chainId)['WARTOKEN']);
                const tokenBalance = await warTokenContract.methods.balanceOf(accounts[0]).call();
                dispatch(setUserAccounts({ accounts, balance, chainId, tokenBalance: Web3.utils.fromWei(tokenBalance) }));

                const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
                const dogeInfo = await getUserInfo(presenterContract, accounts[0], 0);
                const pepeInfo = await getUserInfo(presenterContract, accounts[0], 1);
                const wojakInfo = await getUserInfo(presenterContract, accounts[0], 2);

                dispatch(setUserInfo({dogeInfo, pepeInfo, wojakInfo}));
                dispatch(fetchWalletInfo(accounts[0]));
            }
        });

        // window.ethereum.on('disconnect', () => {
        //     dispatch(disconnect());
        // });

        return window.ethereum.request({ method: 'eth_requestAccounts' })
            .then(async () => {
                const chainId = await window.web3.eth.getChainId();

                const hexChainId = window.web3.utils.toHex(chainId);
                if (ALLOWED_NETWORKS.indexOf(hexChainId) === -1) {
                    dispatch(setConnectModalVisible(false));
                    dispatch(setWrongNetwork(true));
                    needLoad && dispatch(connectWalletFail());
                } else {
                    const chainId = window.ethereum.chainId;
                    const accounts = await window.web3.eth.getAccounts();
                    const balance = await window.web3.eth.getBalance(accounts[0]);

                    const warTokenContract = new window.web3.eth.Contract(WarTokenABI, contractAddresses(chainId)['WARTOKEN']);
                    const tokenBalance = await warTokenContract.methods.balanceOf(accounts[0]).call();
                    dispatch(setUserAccounts({ accounts, balance, chainId, tokenBalance: Web3.utils.fromWei(tokenBalance) }));

                    const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
                    const dogeInfo = await getUserInfo(presenterContract, accounts[0], 0);
                    const pepeInfo = await getUserInfo(presenterContract, accounts[0], 1);
                    const wojakInfo = await getUserInfo(presenterContract, accounts[0], 2);

                    dispatch(setUserInfo({dogeInfo, pepeInfo, wojakInfo}));
                    // Load notifications
                    dispatch(fetchWalletInfo(accounts[0]));

                    needLoad && dispatch(connectWalletSuccess());
                    return true;
                }
            })
            .catch((err) => {
                Logger.error(err);
                needLoad && dispatch(connectWalletFail(err.message));
                return false;
            });
    }

    return new Promise((resolve, reject) => {
        const err = 'Metamask/TrustWallet not installed.';
        const mobileError = 'Please Use MetaMask/TrustWallet Mobile\'s built-in browser';
        needLoad && dispatch(connectWalletFail(isMobile ? mobileError : err));
        dispatch(removeProvider());
        reject(isMobile ? mobileError : err);
    });
};

export const removeProvider = () => async () => {
    window.localStorage.removeItem('connectorId');
    await window.web3?.provider?.disconnect();
    window.web3 = undefined;
}

/** CONNECT TO WALLET CONNECT **/
export const connectToWalletConnect = () => async (dispatch) => {
    try {
        const walletConnectProvider = new WalletConnectProvider({
            infuraId: 'a288a32fdbae4840820ca7260600f195',
            rpc: {
                1: 'https://mainnet.infura.io/v3/a288a32fdbae4840820ca7260600f195',
                4: 'https://ropsten.infura.io/v3/a288a32fdbae4840820ca7260600f195',
                56: 'https://bsc-dataseed1.ninicoin.io',
                97: 'https://data-seed-prebsc-2-s3.binance.org:8545'
            },
            bridge: 'https://bridge.walletconnect.org',
            qrcode: true,
            pollingInterval: 12000
        });

        const supportedChainIds = [56, 97, 1, 4];

        if (!walletConnectProvider.wc.connected) {
            await walletConnectProvider.wc.createSession({
                chainId: supportedChainIds[0]
            });
        }

        //  Enable session (triggers QR Code modal)
        await walletConnectProvider.enable();
        //  Create Web3
        const web3 = new Web3(walletConnectProvider);
        window.web3 = web3;
        window.web3.eth.transactionPollingTimeout = Number(process.env.CONTRACT_TIMEOUT_SECONDS);
        dispatch(connectWalletRequest());

        const chainId = await web3.eth.getChainId();
        const hexChainId = web3.utils.toHex(chainId);

        let accounts = await web3.eth.getAccounts();

        if (ALLOWED_NETWORKS.indexOf(hexChainId) === -1) {
            dispatch(setWrongNetwork(true));
            dispatch(connectWalletFail());
            return false;
        }
        const initUserInfo = async () => {
            accounts = await web3.eth.getAccounts();
            const chainId = await web3.eth.getChainId();
            const hexChainId = web3.utils.toHex(chainId);
            
            if (ALLOWED_NETWORKS.indexOf(hexChainId) === -1) {
                dispatch(setWrongNetwork(true));
                dispatch(connectWalletFail());
                return false;
            }
            
            dispatch(setWrongNetwork(false));
            const ethBalance = await web3.eth.getBalance(accounts[0]);
            const balance = new BigNumber(ethBalance).toJSON();
            const warTokenContract = new window.web3.eth.Contract(WarTokenABI, contractAddresses(chainId)['WARTOKEN']);
            const tokenBalance = await warTokenContract.methods.balanceOf(accounts[0]).call().catch(0);

            dispatch(setNetwork(chainId));
            dispatch(connectWalletSuccess());
            dispatch(setUserAccounts({ accounts, balance, chainId, tokenBalance: Web3.utils.fromWei(tokenBalance) }));

            const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
            const dogeInfo = await getUserInfo(presenterContract, accounts[0], 0);
            const pepeInfo = await getUserInfo(presenterContract, accounts[0], 1);
            const wojakInfo = await getUserInfo(presenterContract, accounts[0], 2);

            dispatch(setUserInfo({dogeInfo, pepeInfo, wojakInfo}));
            dispatch(fetchWalletInfo(accounts[0]));
        }
        await initUserInfo();

        walletConnectProvider.on('accountsChanged', async (accounts) => {
            if (accounts && accounts[0]) {
                await initUserInfo();
            } else {
                dispatch(disconnect());
            }
        });

        // Subscribe to chainId change
        walletConnectProvider.on('chainChanged', async (chainId) => {
            if (!accounts || !accounts[0]) {
                accounts = await web3.eth.getAccounts();
            }
            const hexChainId = web3.utils.toHex(chainId);
            if (ALLOWED_NETWORKS.indexOf(verifyChainId(hexChainId)) === -1) {
                // await clearUserInfo(dispatch);
                dispatch(setConnectModalVisible(false));
                dispatch(setWrongNetwork(true));
                return;
            } else {
                dispatch(setWrongNetwork(false));
            }

            await initUserInfo();
        });

        walletConnectProvider.on('session_update', async (error, payload) => {
            Logger.log(`session_update ${error} ${payload}`);
            if (error) {
                dispatch(connectWalletFail(error));
                dispatch(removeProvider());
                return;
            }
            await initUserInfo();
        });

        walletConnectProvider.on('connect', async () => {
            await initUserInfo();
        });

        walletConnectProvider.on('disconnect', () => {
            dispatch(disconnect());
        });
        return true;
    } catch (error) {
        dispatch(connectWalletFail(error));
        dispatch(removeProvider());
        return false;
    }
};

export const refeshBalance = () => async (dispatch, getState) => {
    try {
        const account = getState()?.user?.userAccount?.accounts[0];
        const chainId = getState()?.user?.chainId;
        if (account && chainId) {
            const warTokenContract = new window.web3.eth.Contract(WarTokenABI, contractAddresses(chainId)['WARTOKEN']);
            const tokenBalance = await warTokenContract.methods.balanceOf(account).call();
            dispatch(setBalance({ tokenBalance: Web3.utils.fromWei(tokenBalance) }));
        }
    } catch (err) {
        Logger.error(err)
    }
};


/** CLEAR TRANSACTION LOGS **/
export const clearTransactionLog = (accountAddress, chainId) => (dispatch, getState) => {
    const transactionLogs = getState().user.transactionLogs;
    const logData = transactionLogs.result;

    const filterLog = filter(logData, item => {
        return item.chainId === chainId && get(item, 'account', '').toLowerCase() !== (accountAddress || '').toLowerCase();
    });

    dispatch(clearTransactionLogs(filterLog));
};

const { userLogout } = createActions({
    USER_LOGOUT: () => {},
});

export const disconnect = () => async (dispatch) => {
    window.localStorage.removeItem('connectorId');
    window.localStorage.removeItem('walletconnect');
    await window.web3?.provider?.disconnect();
    window.web3 = undefined;
    await dispatch(setWrongNetwork(false));
    // window.location.href = window.location.pathname;
    dispatch(userLogout());
};

export const clearUserInfo = async dispatch => {
    await dispatch(setUserAccounts({accounts: []}));
    await dispatch(setUserAccounts({walletUsername:''}));
    await dispatch(setWrongNetwork(false));
    window.localStorage.removeItem('walletconnect');
    window.localStorage.removeItem('nonce');
}

export const refeshUserInfo = async (presenterContract, account, dispatch) => {
    const dogeInfo = await getUserInfo(presenterContract, account, 0);
    const pepeInfo = await getUserInfo(presenterContract, account, 1);
    const wojakInfo = await getUserInfo(presenterContract, account, 2);
    dispatch(setUserInfo({ dogeInfo, pepeInfo, wojakInfo }));
}

export const getDataUserInfoAndUpdate = () => async (dispatch, getState) => {
    try {
        const account = getState()?.user?.userAccount?.accounts[0];
        const chainId = getState()?.user?.chainId;
        if (account && chainId) {
            const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
            const dogeInfo = await getUserInfo(presenterContract, account, 0);
            const pepeInfo = await getUserInfo(presenterContract, account, 1);
            const wojakInfo = await getUserInfo(presenterContract, account, 2);
            dispatch(setUserInfo({ dogeInfo, pepeInfo, wojakInfo }));
        }
    } catch (err){
        Logger.error(err)
    }
};

export const verifyChainId = chainId => {
    if (typeof chainId !== 'number' && typeof chainId !== 'string') {
        return '0x1';
    }
    if (typeof chainId === 'number') {
        return '0x' + chainId.toString(16);
    }
    if (!chainId.startsWith('0x')) {
        return '0x' + parseInt(chainId.replace(/\D+/g, '')).toString(16);
    }
    return chainId;
};

export const CONNECTION_TYPES = {
    metamask: 'metamask',
    walletconnect: 'walletconnect'
};

export const closeNetworkAction = () => async (dispatch) => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (connectorId === CONNECTION_TYPES.metamask) {
        dispatch(setWrongNetwork(false));
    } else if (connectorId === CONNECTION_TYPES.walletconnect) {
        window.localStorage.removeItem('connectorId');
        window.localStorage.removeItem('walletconnect');
        dispatch(setWrongNetwork(false));
    }
}