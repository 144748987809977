import React, { useCallback, useEffect, useState } from 'react';

import closeIcon from './img/close.png';
import tickIcon from './img/tick.png';

import Modal from '../../../Modal';
import Button from '../../../Button';
import TextInput from '../../../TextInput';

import style from './EmailNotificationPopup.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { registerNotify, sendVerifyEmail } from '../../../../store/modules/User/actions';
import { message } from 'antd';
import { validateEmail, onFocusHandle, onBlurHandle } from '../../../../utils';

function EmailNotificationPopup(props) {
    const dispatch = useDispatch()

    const { visible, onCancel } = props;

    const address = useSelector(state => state.user.userAccount.accounts[0] ?? '');
    const nonce = useSelector(state => state?.user?.walletInfo?.nonce ?? '');
    const emailStore = useSelector(state => state?.user?.walletInfo?.email ?? '');
    const verifiedStore = useSelector(state => state?.user?.walletInfo?.verified ?? '');
    const isRewardStore = useSelector(state => state?.user?.walletInfo?.isReward ?? false);
    const isVoteStore = useSelector(state => state?.user?.walletInfo?.isVote ?? false);
    const isFinishStore = useSelector(state => state?.user?.walletInfo?.isFinish ?? false);

    const lists = {
        'Reward': isRewardStore,
        'Vote': isVoteStore,
        'Finish': isFinishStore
    }

    const [valueEmail, setValueEmail] = useState(emailStore);
    const [valueCheck, setValueCheck] = useState(lists);

    useEffect(() => {
        if (address) {
            const values = {
                'Reward': isRewardStore,
                'Vote': isVoteStore,
                'Finish': isFinishStore
            }
            setValueEmail(emailStore);
            setValueCheck(values);
        }
    }, [address, emailStore, verifiedStore, isRewardStore, isVoteStore, isFinishStore])

    const handleCloseModal = useCallback(() => {
        const lists = {
            'Reward': isRewardStore,
            'Vote': isVoteStore,
            'Finish': isFinishStore
        }
        setValueEmail(emailStore);
        setValueCheck(lists);
        onCancel();
    }, [isRewardStore, isVoteStore, isFinishStore, emailStore]);

    const handleValueCheck = useCallback((key, value) => {
        const newValueCheck = {
            ...valueCheck,
            [key]: value
        };
        setValueCheck(newValueCheck);
    }, [valueCheck]);

    const disableVerified = verifiedStore && emailStore === valueEmail;

    const sendVerify = useCallback(async () => {
        if (window.web3) {
            const signKey = await window.web3.eth.personal.sign(nonce, address);
            const body = {
                address,
                email: valueEmail,
                sign: signKey
            };
            return dispatch(sendVerifyEmail(body));
        }
    }, [window.web3, valueEmail]);

    const onSubmit = useCallback(async () => {
        const {
            'Reward': isReward,
            'Vote': isVote,
            'Finish': isFinish,
        } = valueCheck;

        const signKey = await window.web3.eth.personal.sign(nonce, address);
        const body = {
            isReward,
            isVote,
            isFinish,
            address,
            sign: signKey
        }
        dispatch(registerNotify(body))
            .then(res => {
                if (typeof res === 'string') {
                    message.error(res);
                }
                onCancel();
            });
    }, [valueCheck])

    return (
        <Modal
            wrapperClass={style.container}
            simple
            width={'360rem'}
            visible={visible}
            onCancel={() => handleCloseModal()}
            closable={false}
            maskClosable={false}
        >
            <header className={style.header}>
                <section className={style.heading}>
                    <div className={style.title}>Email Notification</div>

                    <img
                        src={closeIcon}
                        alt="close"
                        className={style.closeIcon}
                        onClick={() => handleCloseModal()}
                    />
                </section>

                <section className={style.description}>
                    <div className={style.des}>We will send a notification to your email</div>
                </section>
            </header>

            <div className={style.content}>
                <TextInput
                    value={valueEmail}
                    onFocus={onFocusHandle}
                    onBlur={onBlurHandle}
                    onChange={setValueEmail}
                    placeholder={'Enter your email address'}
                    suffix={
                        <Button
                            wrapperClass={style.verifyBtn}
                            text={disableVerified ? 'VERIFIED' : 'VERIFY'}
                            blue
                            gradient
                            disabled={disableVerified || !validateEmail(valueEmail)}
                            onClick={sendVerify}
                        />
                    }
                />

                <section className={style.listItem}>
                    {
                        Object.keys(valueCheck).map((key, index) => {
                            const value = valueCheck[key];
                            return (
                                <div onClick={() => handleValueCheck(key, !value)} className={style.item} key={index}>
                                    {value ? (
                                        <img src={tickIcon} alt="checked" className={style.radioChecked} />
                                    ) : (
                                        <div className={style.radio} />
                                    )}
                                    <p>{key}</p>
                                </div>
                            )
                        })
                    }
                </section>

                <section className={style.btnContainer}>
                    <Button
                        wrapperClass={style.button}
                        text={'SUBMIT'}
                        primary
                        green
                        disabled={!disableVerified}
                        onClick={onSubmit}
                    />
                </section>
            </div>

            <footer></footer>
        </Modal>
    );
}

export default EmailNotificationPopup;