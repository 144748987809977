import { isEmpty, has } from 'lodash';
import { handleActions } from 'redux-actions';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';
import { get } from 'lodash';

const initialState = {
    connectWallet: {
        error: null,
        isConnect: false,
        requesting: false,
    },
    userInfo: null,
    userAccount: {
        balance: 0,
        tokenBalance: 0,
        accounts: [],
        error: null,
        requesting: false,
    },
    walletInfo: {},
    chainId: '',
    wrongNetwork: false,
    appliedTermsOfService: true,
    transactionLogs: {
        result: [],
    },
    sendVerifyEmail: {
        error: null,
        requesting: false,
        result: null,
    }
};

let userReducer = handleActions({
    SEND_VERIFY_EMAIL_REQUEST: (state) => ({
        ...state,
        sendVerifyEmail: {
            ...state.sendVerifyEmail,
            requesting: true,
        },
    }),
    SEND_VERIFY_EMAIL_SUCCESS: (state, { payload }) => ({
        ...state,
        sendVerifyEmail: {
            ...state.sendVerifyEmail,
            requesting: false,
            result: payload,
            error: null,
        },
    }),
    SEND_VERIFY_EMAIL_FAIL: (state, { payload }) => ({
        ...state,
        sendVerifyEmail: {
            ...state.sendVerifyEmail,
            requesting: false,
            error: payload.error,
        },
    }),

    SET_WALLET_INFO: (state, { payload }) => ({
        ...state,
        walletInfo: payload,
    }),

    SET_APPLIED_TERMS_OF_SERVICE: (state, { payload }) => ({
        ...state,
        appliedTermsOfService: payload,
    }),

    SET_WRONG_NETWORK: (state, { payload }) => ({
        ...state,
        wrongNetwork: payload,
    }),

    SET_NETWORK: (state, { payload }) => ({
        ...state,
        chainId: payload,
    }),

    /** SET USER ACCOUNTS **/
    SET_USER_ACCOUNTS: (state, { payload }) => ({
        ...state,
        userAccount: {
            ...state.userAccount,
            accounts: has(payload, 'accounts') ? payload.accounts : state.userAccount.accounts,
            balance: has(payload, 'balance') ? payload.balance : state.userAccount.balance,
            tokenBalance: has(payload, 'tokenBalance') ? payload.tokenBalance: state.userAccount.tokenBalance
        },
        connectWallet: {
            ...state.connectWallet,
            ...{ isConnect: has(payload, 'accounts') ? !isEmpty(payload.accounts) : !isEmpty(state.userAccount.accounts) },
        },
        chainId: has(payload, 'chainId') ? payload.chainId : state.chainId
    }),

    /** SET BLANACE **/
    SET_BALANCE: (state, { payload }) => ({
        ...state,
        userAccount: {
            ...state.userAccount,
            tokenBalance: has(payload, 'tokenBalance') ? payload.tokenBalance: state.userAccount.tokenBalance
        }
    }),

    SET_USER_INFO: (state, { payload }) => ({
        ...state,
        userInfo: {
            doge: payload.dogeInfo,
            pepe: payload.pepeInfo,
            wojak: payload.wojakInfo
        }
    }),

    /** CONNECT WALLET **/
    CONNECT_WALLET_REQUEST: (state) => ({
        ...state,
        connectWallet: {
            ...state.connectWallet,
            requesting: true,
        },
    }),
    CONNECT_WALLET_SUCCESS: (state) => ({
        ...state,
        connectWallet: {
            ...state.connectWallet,
            requesting: false,
            isConnect: true,
            error: null,
        },
    }),
    CONNECT_WALLET_FAIL: (state, { payload }) => ({
        ...state,
        connectWallet: {
            ...state.connectWallet,
            requesting: false,
            error: payload.error,
        },
    }),

    /* CLEAR TRANSACTION LOGS */
    CLEAR_TRANSACTION_LOGS: (state, { payload }) => ({
        ...state,
        transactionLogs: {
            result: payload,
        },
    }),
    /* STORE TRANSACTION LOG */
    STORE_TRANSACTION_LOG: (state, { payload }) => ({
        ...state,
        transactionLogs: {
            result: [
                ...get(state, 'transactionLogs.result', []),
                payload,
            ],
        },
    }),
    USER_LOGOUT: state => ({
        ...state,
        connectWallet: initialState.connectWallet,
        userInfo: initialState.userInfo,
        userAccount: initialState.userAccount,
        walletInfo: initialState.walletInfo,
        wrongNetwork: initialState.wrongNetwork,
        sendVerifyEmail: initialState.sendVerifyEmail,
    }),
}, initialState);

userReducer = persistReducer({
    key: 'user',
    storage: localForage,
    whitelist: ['userAccount', 'transactionLogs', 'chainId', 'appliedTermsOfService']
}, userReducer);

export default userReducer;
